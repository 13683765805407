import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "prerequisites"
    }}>{`Prerequisites`}</h3>
    <p>{`To use PaymentsDS with the PHP SDK you need to have:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.php.net"
        }}>{`PHP 7.2+`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://getcomposer.org"
        }}>{`Composer`}</a></li>
    </ul>
    <h3 {...{
      "id": "receive-money-from-a-mobile-account-to-a-business-account"
    }}>{`Receive money from a mobile account to a business account`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`use Paymentsds\\MPesa\\Client;

$client = new Client([
   'apiKey' => '<REPLACE>',             // API Key
   'publicKey' => '<REPLACE>',          // Public Key
   'serviceProviderCode' => '<REPLACE>' // input_ServiceProviderCode
]);

try {
   $paymentData = [
      'from' => '841234567',       // input_CustomerMSISDN
      'reference' => '11114',      // input_ThirdPartyReference
      'transaction' => 'T12344CC', // input_TransactionReference
      'amount' => '10'             // input_Amount
   ];

   $result = $client.receive($paymentData);

   if ($result.isSuccess()) {
      var_dump($result);
   }
} catch ($e) {
   var_dump($e);
}
`}</code></pre>
    <h3 {...{
      "id": "send-money-from-a-business-account-to-a-mobile-account"
    }}>{`Send money from a business account to a mobile account`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`use Paymentsds\\MPesa\\Client;

$client = new Client([
   'apiKey' => '<REPLACE>',             // API Key
   'publicKey' => '<REPLACE>',          // Public Key
   'serviceProviderCode' => '<REPLACE>' // input_ServiceProviderCode
]);

try {
   $paymentData = [
      'to' => '841234567',         // input_CustomerMSISDN
      'reference' => '11114',      // input_ThirdPartyReference
      'transaction' => 'T12344CC', // input_TransactionReference
      'amount' => '10'             // input_Amount
   ];

   $result = $client.send($paymentData);

   if ($result.isSuccess()) {
      var_dump($result);
   }
} catch ($e) {
   var_dump($e);
}
`}</code></pre>
    <h3 {...{
      "id": "send-money-from-a-business-account-to-a-another-business-account"
    }}>{`Send money from a business account to a another business account`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`use Paymentsds\\MPesa\\Client;

$client = new Client([
   'apiKey' => '<REPLACE>',             // API Key
   'publicKey' => '<REPLACE>',          // Public Key
   'serviceProviderCode' => '<REPLACE>' // input_ServiceProviderCode
]);

try {
   $paymentData = [
      'to' => '979797',         // input_ReceiverPartyCode
      'reference' => '11114',      // input_ThirdPartyReference
      'transaction' => 'T12344CC', // input_TransactionReference
      'amount' => '10'             // input_Amount
   ];

   $result = $client.send($paymentData);

   if ($result.isSuccess()) {
      var_dump($result);
   }
} catch ($e) {
   var_dump($e);
}
`}</code></pre>
    <h3 {...{
      "id": "revert-a-transaction"
    }}>{`Revert a transaction`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`use Paymentsds\\MPesa\\Client;

$client = new Client([
   'apiKey' => '<REPLACE>',             // API Key
   'publicKey' => '<REPLACE>',          // Public Key
   'serviceProviderCode' => '<REPLACE>', // input_ServiceProviderCode
   'initiatorIdentifier' => '<REPLACE>', // input_InitiatorIdentifier,
   'securityIdentifier' => '<REPLACE>'  // input_SecurityCredential
]);

try {
   $paymentData = [
      'reference' => '11114',      // input_ThirdPartyReference
      'transaction' => 'T12344CC', // input_TransactionReference
      'amount' => '10'             // input_Amount
   ];

   $result = $client.revert($paymentData);

   if ($result.isSuccess()) {
      var_dump($result);
   }
} catch ($e) {
   var_dump($e);
}
`}</code></pre>
    <h3 {...{
      "id": "query-the-status-of-a-transaction"
    }}>{`Query the status of a transaction`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`use Paymentsds\\MPesa\\Client;
$client = new Client([
   'apiKey' => '<REPLACE>',             // API Key
   'publicKey' => '<REPLACE>',          // Public Key
   'serviceProviderCode' => '<REPLACE>', // input_ServiceProviderCode
]);
$paymentData = [
   'subject' => '11114',      // input_QueryReference
   'reference' => 'T12344CC', // input_ThirdPartyReference
];
$result = $client.query($paymentData);
if ($result->success) {
   // Handle success scenario
} else {
   // Handle failure scenario
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      